import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from '../components/Post'
import PaginationLinks from '../components/PaginationLinks'

const IndexPage = () => {
  
  const postsPerPage = 5;
  let numberOfPages = 1;

  var currentPage = 1
  var baseLink = "lonwheels.us/"

  if(typeof(window) !== 'undefined')
  {
    currentPage = parseInt(window.location.href.toString().split('p=')[1])
    baseLink = window.location.href.toString().split('/?')[0]
  }
  
  if(!currentPage)
  {
      currentPage = 1;
  }

  return (
  <Layout pageTitle="L OnWheels">
    <SEO title="Home" keywords={[`LOnWheels`, `Skates`, `RollerSkates`, `CandiGirl`,`gatsby`,`application`,`react`]} />
    
      <StaticQuery query={indexQuery} render={data=>{
        
        numberOfPages = Math.ceil(data.allMarkdownRemark.totalCount / postsPerPage)

        let posts = []

        var startIndex = (currentPage-1) * postsPerPage;
        var endIndex = currentPage * postsPerPage;

        if(endIndex > data.allMarkdownRemark.totalCount)
        {
          endIndex = data.allMarkdownRemark.totalCount;
        }
        
        for(var i = startIndex; i < endIndex; i++){
          var node = data.allMarkdownRemark.edges[i].node
          posts.push(
            <Post
              key = {node.id}
              title = {node.frontmatter.title}
              date={node.frontmatter.date}
              author={node.frontmatter.author}
              slug={node.fields.slug}
              body={node.excerpt}
              fluid={node.frontmatter.image.childImageSharp.fluid}
              tags={node.frontmatter.tags}
              imageName={node.frontmatter.image.name}
              type={node.frontmatter.type}
              html={node.html}
              videoID={node.frontmatter.videoID}
          ></Post>
          )
        }
          

          /*<Post
              key = {node.id}
              title = {node.frontmatter.title}
              date={node.frontmatter.date}
              author={node.frontmatter.author}
              slug={node.fields.slug}
              body={node.excerpt}
              fluid={node.frontmatter.image.childImageSharp.fluid}
              tags={node.frontmatter.tags}
              imageName={node.frontmatter.image.name}
              type={node.frontmatter.type}
              html={node.html}
              videoID={node.frontmatter.videoID}
          ></Post>*/
        

      return(
        <div>
          {posts}
        <PaginationLinks currentPage={currentPage} numberOfPages={numberOfPages} baseLink={baseLink}/>
        </div>
        
      )
    }}
    />
    
  </Layout>
)
  }

const indexQuery = graphql`
query indexQuery{
  allMarkdownRemark(
   sort: {fields: [frontmatter___date],order: DESC}
  ){
    totalCount
    edges{
      node{
        id
        html
        frontmatter{
          title
          date(formatString: "MMM D YYYY")
          author
          type
          videoID
          image{
            name
            childImageSharp{
              fluid(maxWidth: 600){
                ...GatsbyImageSharpFluid
              }
            }
          }
          tags
        }
        fields{
          slug
        }
        excerpt
      }
    }
  }
}`

export default IndexPage


/*
  --dd-color-background: #ffffff;
  --dd-color-lpink: #ff9e9d;
  --dd-color-dpink: #ff3d7f;
  --dd-color-yellow: #dad8a7;
  --dd-color-lteal: #7fc7af;
  --dd-color-dteal: #3db8af;
*/